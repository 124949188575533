import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '~/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_54604f30 from 'nuxt_plugin_plugin_54604f30' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_674415ee from 'nuxt_plugin_cookieuniversalnuxt_674415ee' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_storage_162f04e1 from 'nuxt_plugin_storage_162f04e1' // Source: ./storage.js (mode: 'all')
import nuxt_plugin_vuesocialsharingplugin_097f1e3f from 'nuxt_plugin_vuesocialsharingplugin_097f1e3f' // Source: ./vue-social-sharing-plugin.js (mode: 'all')
import nuxt_plugin_vuesweetalert2_612b7746 from 'nuxt_plugin_vuesweetalert2_612b7746' // Source: ./vue-sweetalert2.js (mode: 'client')
import nuxt_plugin_toast_e867e216 from 'nuxt_plugin_toast_e867e216' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_axios_80c87b74 from 'nuxt_plugin_axios_80c87b74' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_pluginutils_ea6a7e96 from 'nuxt_plugin_pluginutils_ea6a7e96' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_8dc41680 from 'nuxt_plugin_pluginrouting_8dc41680' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_7e05a2a2 from 'nuxt_plugin_pluginmain_7e05a2a2' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_httpserver_5d3baab3 from 'nuxt_plugin_httpserver_5d3baab3' // Source: ./http.server.js (mode: 'server')
import nuxt_plugin_http_3444216c from 'nuxt_plugin_http_3444216c' // Source: ./http.js (mode: 'all')
import nuxt_plugin_helpers_1c816d70 from 'nuxt_plugin_helpers_1c816d70' // Source: ../plugins/helpers.js (mode: 'all')
import nuxt_plugin_contentplaceholder_630f6550 from 'nuxt_plugin_contentplaceholder_630f6550' // Source: ../plugins/contentplaceholder (mode: 'client')
import nuxt_plugin_infiniteloading_68d2fb1c from 'nuxt_plugin_infiniteloading_68d2fb1c' // Source: ../plugins/infiniteloading (mode: 'client')
import nuxt_plugin_swiper_3a1c5924 from 'nuxt_plugin_swiper_3a1c5924' // Source: ../plugins/swiper (mode: 'client')
import nuxt_plugin_datepicker_4a26849c from 'nuxt_plugin_datepicker_4a26849c' // Source: ../plugins/datepicker (mode: 'client')
import nuxt_plugin_creditcard_68bfc17f from 'nuxt_plugin_creditcard_68bfc17f' // Source: ../plugins/creditcard (mode: 'client')
import nuxt_plugin_mask_6a837762 from 'nuxt_plugin_mask_6a837762' // Source: ../plugins/mask (mode: 'client')
import nuxt_plugin_modal_341cce12 from 'nuxt_plugin_modal_341cce12' // Source: ../plugins/modal (mode: 'client')
import nuxt_plugin_validation_f079ef22 from 'nuxt_plugin_validation_f079ef22' // Source: ../plugins/validation (mode: 'client')
import nuxt_plugin_directives_d0867c0c from 'nuxt_plugin_directives_d0867c0c' // Source: ../plugins/directives (mode: 'all')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_http_6a8178fe from 'nuxt_plugin_http_6a8178fe' // Source: ../plugins/http (mode: 'all')
import nuxt_plugin_vueselect_2bcaa968 from 'nuxt_plugin_vueselect_2bcaa968' // Source: ../plugins/vueselect (mode: 'all')
import nuxt_plugin_rating_3fe846da from 'nuxt_plugin_rating_3fe846da' // Source: ../plugins/rating (mode: 'client')
import nuxt_plugin_lazy_6a8303ea from 'nuxt_plugin_lazy_6a8303ea' // Source: ../plugins/lazy (mode: 'all')
import nuxt_plugin_printopaper_46730b98 from 'nuxt_plugin_printopaper_46730b98' // Source: ../plugins/printopaper (mode: 'all')
import nuxt_plugin_auth_7543862a from 'nuxt_plugin_auth_7543862a' // Source: ./auth.js (mode: 'all')
import nuxt_plugin_auth_7f7561ce from 'nuxt_plugin_auth_7f7561ce' // Source: ../plugins/auth.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Digital Tire Assistant","titleTemplate":"%s | Tirelet","htmlAttrs":{"lang":"el"},"script":[{"src":"https:\u002F\u002Fjs.everypay.gr\u002Fv3"}],"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, user-scalable=no"},{"http-equiv":"X-UA-Compatible","content":"IE=Edge"},{"hid":"description","name":"description","content":""},{"name":"format-detection","content":"telephone=no"},{"hid":"og:locale","property":"og:locale","content":"el"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Fimg-v2\u002Ftirelet-favicon.png"},{"rel":"stylesheet","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"},{"rel":"stylesheet","href":"https:\u002F\u002Fcdn.jsdelivr.net\u002Fnpm\u002Fbootstrap@4.0.0\u002Fdist\u002Fcss\u002Fbootstrap.min.css"},{"rel":"stylesheet","href":"https:\u002F\u002Fcdnjs.cloudflare.com\u002Fajax\u002Flibs\u002Ffont-awesome\u002F4.7.0\u002Fcss\u002Ffont-awesome.min.css"}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_54604f30 === 'function') {
    await nuxt_plugin_plugin_54604f30(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_674415ee === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_674415ee(app.context, inject)
  }

  if (typeof nuxt_plugin_storage_162f04e1 === 'function') {
    await nuxt_plugin_storage_162f04e1(app.context, inject)
  }

  if (typeof nuxt_plugin_vuesocialsharingplugin_097f1e3f === 'function') {
    await nuxt_plugin_vuesocialsharingplugin_097f1e3f(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuesweetalert2_612b7746 === 'function') {
    await nuxt_plugin_vuesweetalert2_612b7746(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_e867e216 === 'function') {
    await nuxt_plugin_toast_e867e216(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_80c87b74 === 'function') {
    await nuxt_plugin_axios_80c87b74(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_ea6a7e96 === 'function') {
    await nuxt_plugin_pluginutils_ea6a7e96(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_8dc41680 === 'function') {
    await nuxt_plugin_pluginrouting_8dc41680(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_7e05a2a2 === 'function') {
    await nuxt_plugin_pluginmain_7e05a2a2(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_httpserver_5d3baab3 === 'function') {
    await nuxt_plugin_httpserver_5d3baab3(app.context, inject)
  }

  if (typeof nuxt_plugin_http_3444216c === 'function') {
    await nuxt_plugin_http_3444216c(app.context, inject)
  }

  if (typeof nuxt_plugin_helpers_1c816d70 === 'function') {
    await nuxt_plugin_helpers_1c816d70(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_contentplaceholder_630f6550 === 'function') {
    await nuxt_plugin_contentplaceholder_630f6550(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_infiniteloading_68d2fb1c === 'function') {
    await nuxt_plugin_infiniteloading_68d2fb1c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_swiper_3a1c5924 === 'function') {
    await nuxt_plugin_swiper_3a1c5924(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_datepicker_4a26849c === 'function') {
    await nuxt_plugin_datepicker_4a26849c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_creditcard_68bfc17f === 'function') {
    await nuxt_plugin_creditcard_68bfc17f(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_mask_6a837762 === 'function') {
    await nuxt_plugin_mask_6a837762(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_modal_341cce12 === 'function') {
    await nuxt_plugin_modal_341cce12(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_validation_f079ef22 === 'function') {
    await nuxt_plugin_validation_f079ef22(app.context, inject)
  }

  if (typeof nuxt_plugin_directives_d0867c0c === 'function') {
    await nuxt_plugin_directives_d0867c0c(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (typeof nuxt_plugin_http_6a8178fe === 'function') {
    await nuxt_plugin_http_6a8178fe(app.context, inject)
  }

  if (typeof nuxt_plugin_vueselect_2bcaa968 === 'function') {
    await nuxt_plugin_vueselect_2bcaa968(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_rating_3fe846da === 'function') {
    await nuxt_plugin_rating_3fe846da(app.context, inject)
  }

  if (typeof nuxt_plugin_lazy_6a8303ea === 'function') {
    await nuxt_plugin_lazy_6a8303ea(app.context, inject)
  }

  if (typeof nuxt_plugin_printopaper_46730b98 === 'function') {
    await nuxt_plugin_printopaper_46730b98(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_7543862a === 'function') {
    await nuxt_plugin_auth_7543862a(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_7f7561ce === 'function') {
    await nuxt_plugin_auth_7f7561ce(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
