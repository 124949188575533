export default {
  namespaced: true,
  state: () => ({
    list: [],
    isSub: true
  }),
  mutations: {
    setList(state, payload) {
      state.list = payload
    },
    setSub(state, payload) {
      state.isSub = payload
    }
  }
}
