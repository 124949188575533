export const SsrCarousel = () => import('../../node_modules/vue-ssr-carousel/index.js' /* webpackChunkName: "components/ssr-carousel" */).then(c => wrapFunctional(c.default || c))
export const AddressAdd = () => import('../../components/address/add.vue' /* webpackChunkName: "components/address-add" */).then(c => wrapFunctional(c.default || c))
export const AddcarbarCarbrandbar = () => import('../../components/addcarbar/carbrandbar.vue' /* webpackChunkName: "components/addcarbar-carbrandbar" */).then(c => wrapFunctional(c.default || c))
export const AddcarbarCarcarbar = () => import('../../components/addcarbar/carcarbar.vue' /* webpackChunkName: "components/addcarbar-carcarbar" */).then(c => wrapFunctional(c.default || c))
export const AddcarbarCarfuelbar = () => import('../../components/addcarbar/carfuelbar.vue' /* webpackChunkName: "components/addcarbar-carfuelbar" */).then(c => wrapFunctional(c.default || c))
export const AddcarbarCargearbar = () => import('../../components/addcarbar/cargearbar.vue' /* webpackChunkName: "components/addcarbar-cargearbar" */).then(c => wrapFunctional(c.default || c))
export const AddcarbarCarmodelbar = () => import('../../components/addcarbar/carmodelbar.vue' /* webpackChunkName: "components/addcarbar-carmodelbar" */).then(c => wrapFunctional(c.default || c))
export const AddcarbarCarstylebar = () => import('../../components/addcarbar/carstylebar.vue' /* webpackChunkName: "components/addcarbar-carstylebar" */).then(c => wrapFunctional(c.default || c))
export const AddcarbarCartirebar = () => import('../../components/addcarbar/cartirebar.vue' /* webpackChunkName: "components/addcarbar-cartirebar" */).then(c => wrapFunctional(c.default || c))
export const AddcarbarCarversionbar = () => import('../../components/addcarbar/carversionbar.vue' /* webpackChunkName: "components/addcarbar-carversionbar" */).then(c => wrapFunctional(c.default || c))
export const AddcarbarCaryearbar = () => import('../../components/addcarbar/caryearbar.vue' /* webpackChunkName: "components/addcarbar-caryearbar" */).then(c => wrapFunctional(c.default || c))
export const Addcarbar = () => import('../../components/addcarbar/index.vue' /* webpackChunkName: "components/addcarbar" */).then(c => wrapFunctional(c.default || c))
export const BannersCookie = () => import('../../components/banners/cookie.vue' /* webpackChunkName: "components/banners-cookie" */).then(c => wrapFunctional(c.default || c))
export const BranchesBranchbar = () => import('../../components/branches/branchbar.vue' /* webpackChunkName: "components/branches-branchbar" */).then(c => wrapFunctional(c.default || c))
export const CampaignsCampaigncarousel = () => import('../../components/campaigns/campaigncarousel.vue' /* webpackChunkName: "components/campaigns-campaigncarousel" */).then(c => wrapFunctional(c.default || c))
export const Carousel = () => import('../../components/carousel/carousel.vue' /* webpackChunkName: "components/carousel" */).then(c => wrapFunctional(c.default || c))
export const CarouselSlide = () => import('../../components/carousel/slide.vue' /* webpackChunkName: "components/carousel-slide" */).then(c => wrapFunctional(c.default || c))
export const CartCartheader = () => import('../../components/cart/cartheader.vue' /* webpackChunkName: "components/cart-cartheader" */).then(c => wrapFunctional(c.default || c))
export const Creditcard = () => import('../../components/creditcard/creditcard.vue' /* webpackChunkName: "components/creditcard" */).then(c => wrapFunctional(c.default || c))
export const Feedbacks = () => import('../../components/feedbacks/index.vue' /* webpackChunkName: "components/feedbacks" */).then(c => wrapFunctional(c.default || c))
export const HomeBestsellers = () => import('../../components/home/bestsellers.vue' /* webpackChunkName: "components/home-bestsellers" */).then(c => wrapFunctional(c.default || c))
export const HomeHowdoes = () => import('../../components/home/howdoes.vue' /* webpackChunkName: "components/home-howdoes" */).then(c => wrapFunctional(c.default || c))
export const IconsMenuIcon = () => import('../../components/icons/menuIcon.vue' /* webpackChunkName: "components/icons-menu-icon" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/loading/index.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const MenusMegamenu2 = () => import('../../components/menus/megamenu2.vue' /* webpackChunkName: "components/menus-megamenu2" */).then(c => wrapFunctional(c.default || c))
export const PaymentError = () => import('../../components/payment/error.vue' /* webpackChunkName: "components/payment-error" */).then(c => wrapFunctional(c.default || c))
export const PaymentSuccess = () => import('../../components/payment/success.vue' /* webpackChunkName: "components/payment-success" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersAppointmentplaceholder = () => import('../../components/placeholders/appointmentplaceholder.vue' /* webpackChunkName: "components/placeholders-appointmentplaceholder" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersBranchdetailplaceholder = () => import('../../components/placeholders/branchdetailplaceholder.vue' /* webpackChunkName: "components/placeholders-branchdetailplaceholder" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersCampaigndetailplaceholder = () => import('../../components/placeholders/campaigndetailplaceholder.vue' /* webpackChunkName: "components/placeholders-campaigndetailplaceholder" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersCampaignlistplaceholder = () => import('../../components/placeholders/campaignlistplaceholder.vue' /* webpackChunkName: "components/placeholders-campaignlistplaceholder" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersCampaignswiperplaceholder = () => import('../../components/placeholders/campaignswiperplaceholder.vue' /* webpackChunkName: "components/placeholders-campaignswiperplaceholder" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersCartproductplaceholder = () => import('../../components/placeholders/cartproductplaceholder.vue' /* webpackChunkName: "components/placeholders-cartproductplaceholder" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersProductdetailplaceholder = () => import('../../components/placeholders/productdetailplaceholder.vue' /* webpackChunkName: "components/placeholders-productdetailplaceholder" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersProductgridplaceholder = () => import('../../components/placeholders/productgridplaceholder.vue' /* webpackChunkName: "components/placeholders-productgridplaceholder" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersProductlistplaceholder = () => import('../../components/placeholders/productlistplaceholder.vue' /* webpackChunkName: "components/placeholders-productlistplaceholder" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersSelectplaceholder = () => import('../../components/placeholders/selectplaceholder.vue' /* webpackChunkName: "components/placeholders-selectplaceholder" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersServicelistplaceholder = () => import('../../components/placeholders/servicelistplaceholder.vue' /* webpackChunkName: "components/placeholders-servicelistplaceholder" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersStaticplaceholder = () => import('../../components/placeholders/staticplaceholder.vue' /* webpackChunkName: "components/placeholders-staticplaceholder" */).then(c => wrapFunctional(c.default || c))
export const ProductProductcard = () => import('../../components/product/productcard.vue' /* webpackChunkName: "components/product-productcard" */).then(c => wrapFunctional(c.default || c))
export const ProductProductdetailheader = () => import('../../components/product/productdetailheader.vue' /* webpackChunkName: "components/product-productdetailheader" */).then(c => wrapFunctional(c.default || c))
export const ProductsNewproductswiper = () => import('../../components/products/newproductswiper.vue' /* webpackChunkName: "components/products-newproductswiper" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductlistcard = () => import('../../components/products/productlistcard.vue' /* webpackChunkName: "components/products-productlistcard" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductlistgrid = () => import('../../components/products/productlistgrid.vue' /* webpackChunkName: "components/products-productlistgrid" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductlistgrid2 = () => import('../../components/products/productlistgrid2.vue' /* webpackChunkName: "components/products-productlistgrid2" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductswiper = () => import('../../components/products/productswiper.vue' /* webpackChunkName: "components/products-productswiper" */).then(c => wrapFunctional(c.default || c))
export const Rates = () => import('../../components/rates/index.vue' /* webpackChunkName: "components/rates" */).then(c => wrapFunctional(c.default || c))
export const SearchBrandmegabar = () => import('../../components/search/brandmegabar.vue' /* webpackChunkName: "components/search-brandmegabar" */).then(c => wrapFunctional(c.default || c))
export const SearchCitymegabar = () => import('../../components/search/citymegabar.vue' /* webpackChunkName: "components/search-citymegabar" */).then(c => wrapFunctional(c.default || c))
export const SearchHeightmegabar = () => import('../../components/search/heightmegabar.vue' /* webpackChunkName: "components/search-heightmegabar" */).then(c => wrapFunctional(c.default || c))
export const SearchProductbar = () => import('../../components/search/productbar.vue' /* webpackChunkName: "components/search-productbar" */).then(c => wrapFunctional(c.default || c))
export const SearchProductbranchbar = () => import('../../components/search/productbranchbar.vue' /* webpackChunkName: "components/search-productbranchbar" */).then(c => wrapFunctional(c.default || c))
export const SearchRadiusmegabar = () => import('../../components/search/radiusmegabar.vue' /* webpackChunkName: "components/search-radiusmegabar" */).then(c => wrapFunctional(c.default || c))
export const SearchRunflatmegabar = () => import('../../components/search/runflatmegabar.vue' /* webpackChunkName: "components/search-runflatmegabar" */).then(c => wrapFunctional(c.default || c))
export const SearchSearchbar = () => import('../../components/search/searchbar.vue' /* webpackChunkName: "components/search-searchbar" */).then(c => wrapFunctional(c.default || c))
export const SearchSearchmegamenu = () => import('../../components/search/searchmegamenu.vue' /* webpackChunkName: "components/search-searchmegamenu" */).then(c => wrapFunctional(c.default || c))
export const SearchSeasonmegabar = () => import('../../components/search/seasonmegabar.vue' /* webpackChunkName: "components/search-seasonmegabar" */).then(c => wrapFunctional(c.default || c))
export const SearchWidthmegabar = () => import('../../components/search/widthmegabar.vue' /* webpackChunkName: "components/search-widthmegabar" */).then(c => wrapFunctional(c.default || c))
export const Select2Black = () => import('../../components/select2/black.vue' /* webpackChunkName: "components/select2-black" */).then(c => wrapFunctional(c.default || c))
export const Select2 = () => import('../../components/select2/index.vue' /* webpackChunkName: "components/select2" */).then(c => wrapFunctional(c.default || c))
export const Select2White = () => import('../../components/select2/white.vue' /* webpackChunkName: "components/select2-white" */).then(c => wrapFunctional(c.default || c))
export const ServicesServicecarousel = () => import('../../components/services/servicecarousel.vue' /* webpackChunkName: "components/services-servicecarousel" */).then(c => wrapFunctional(c.default || c))
export const SidebarsCart = () => import('../../components/sidebars/cart.vue' /* webpackChunkName: "components/sidebars-cart" */).then(c => wrapFunctional(c.default || c))
export const SidebarsPayment = () => import('../../components/sidebars/payment.vue' /* webpackChunkName: "components/sidebars-payment" */).then(c => wrapFunctional(c.default || c))
export const SidebarsUser = () => import('../../components/sidebars/user.vue' /* webpackChunkName: "components/sidebars-user" */).then(c => wrapFunctional(c.default || c))
export const SubscribesSubscribe = () => import('../../components/subscribes/subscribe.vue' /* webpackChunkName: "components/subscribes-subscribe" */).then(c => wrapFunctional(c.default || c))
export const SwiperCampaignswiper = () => import('../../components/swiper/campaignswiper.vue' /* webpackChunkName: "components/swiper-campaignswiper" */).then(c => wrapFunctional(c.default || c))
export const Swiper = () => import('../../components/swiper/swiper.vue' /* webpackChunkName: "components/swiper" */).then(c => wrapFunctional(c.default || c))
export const Tooltip = () => import('../../components/tooltip/index.vue' /* webpackChunkName: "components/tooltip" */).then(c => wrapFunctional(c.default || c))
export const VselectBlack = () => import('../../components/vselect/black.vue' /* webpackChunkName: "components/vselect-black" */).then(c => wrapFunctional(c.default || c))
export const VselectBranch = () => import('../../components/vselect/branch.vue' /* webpackChunkName: "components/vselect-branch" */).then(c => wrapFunctional(c.default || c))
export const VselectNavbar = () => import('../../components/vselect/navbar.vue' /* webpackChunkName: "components/vselect-navbar" */).then(c => wrapFunctional(c.default || c))
export const VselectWhite = () => import('../../components/vselect/white.vue' /* webpackChunkName: "components/vselect-white" */).then(c => wrapFunctional(c.default || c))
export const AutoAppraisalAddressAdd = () => import('../../components/auto-appraisal/address/add.vue' /* webpackChunkName: "components/auto-appraisal-address-add" */).then(c => wrapFunctional(c.default || c))
export const AutoAppraisalBranchesBranchbar = () => import('../../components/auto-appraisal/branches/branchbar.vue' /* webpackChunkName: "components/auto-appraisal-branches-branchbar" */).then(c => wrapFunctional(c.default || c))
export const AutoAppraisalCartCartheader = () => import('../../components/auto-appraisal/cart/cartheader.vue' /* webpackChunkName: "components/auto-appraisal-cart-cartheader" */).then(c => wrapFunctional(c.default || c))
export const AutoAppraisalFeedbacksApFeedbacks = () => import('../../components/auto-appraisal/feedbacks/apFeedbacks.vue' /* webpackChunkName: "components/auto-appraisal-feedbacks-ap-feedbacks" */).then(c => wrapFunctional(c.default || c))
export const AutoAppraisalPlaceholdersProductlistplaceholder = () => import('../../components/auto-appraisal/placeholders/productlistplaceholder.vue' /* webpackChunkName: "components/auto-appraisal-placeholders-productlistplaceholder" */).then(c => wrapFunctional(c.default || c))
export const AutoAppraisalProductsProductlistcard = () => import('../../components/auto-appraisal/products/productlistcard.vue' /* webpackChunkName: "components/auto-appraisal-products-productlistcard" */).then(c => wrapFunctional(c.default || c))
export const AutoAppraisalProductsProductlistgrid = () => import('../../components/auto-appraisal/products/productlistgrid.vue' /* webpackChunkName: "components/auto-appraisal-products-productlistgrid" */).then(c => wrapFunctional(c.default || c))
export const AutoAppraisalProductsProductswiper = () => import('../../components/auto-appraisal/products/productswiper.vue' /* webpackChunkName: "components/auto-appraisal-products-productswiper" */).then(c => wrapFunctional(c.default || c))
export const AutoAppraisalSearchProductbar = () => import('../../components/auto-appraisal/search/productbar.vue' /* webpackChunkName: "components/auto-appraisal-search-productbar" */).then(c => wrapFunctional(c.default || c))
export const AutoAppraisalSidebarsCart = () => import('../../components/auto-appraisal/sidebars/cart.vue' /* webpackChunkName: "components/auto-appraisal-sidebars-cart" */).then(c => wrapFunctional(c.default || c))
export const AutoAppraisalSidebarsPayment = () => import('../../components/auto-appraisal/sidebars/payment.vue' /* webpackChunkName: "components/auto-appraisal-sidebars-payment" */).then(c => wrapFunctional(c.default || c))
export const AutoAppraisalSidebarsUser = () => import('../../components/auto-appraisal/sidebars/user.vue' /* webpackChunkName: "components/auto-appraisal-sidebars-user" */).then(c => wrapFunctional(c.default || c))
export const AutoAppraisalSwiperApCampaignswiper = () => import('../../components/auto-appraisal/swiper/apCampaignswiper.vue' /* webpackChunkName: "components/auto-appraisal-swiper-ap-campaignswiper" */).then(c => wrapFunctional(c.default || c))
export const AutoAppraisalSwiperBranchDevices = () => import('../../components/auto-appraisal/swiper/branchDevices.vue' /* webpackChunkName: "components/auto-appraisal-swiper-branch-devices" */).then(c => wrapFunctional(c.default || c))
export const AutoAppraisalSwiperHomeCampaignsSwiperTop = () => import('../../components/auto-appraisal/swiper/homeCampaignsSwiperTop.vue' /* webpackChunkName: "components/auto-appraisal-swiper-home-campaigns-swiper-top" */).then(c => wrapFunctional(c.default || c))
export const AutoAppraisalSwiperHomeOtherServicesSwiper = () => import('../../components/auto-appraisal/swiper/homeOtherServicesSwiper.vue' /* webpackChunkName: "components/auto-appraisal-swiper-home-other-services-swiper" */).then(c => wrapFunctional(c.default || c))
export const AutoAppraisalSwiperHomeProductsSwiper = () => import('../../components/auto-appraisal/swiper/homeProductsSwiper.vue' /* webpackChunkName: "components/auto-appraisal-swiper-home-products-swiper" */).then(c => wrapFunctional(c.default || c))
export const AutoAppraisalSwiperProductSwiper = () => import('../../components/auto-appraisal/swiper/productSwiper.vue' /* webpackChunkName: "components/auto-appraisal-swiper-product-swiper" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
