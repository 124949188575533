const middleware = {}

middleware['isAuth'] = require('../middleware/isAuth.js')
middleware['isAuth'] = middleware['isAuth'].default || middleware['isAuth']

middleware['redirect'] = require('../middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['route-middleware'] = require('../middleware/route-middleware.js')
middleware['route-middleware'] = middleware['route-middleware'].default || middleware['route-middleware']

middleware['session'] = require('../middleware/session.js')
middleware['session'] = middleware['session'].default || middleware['session']

export default middleware
