
export default {
  name: "empty",
  mounted() {
    if (this.$store.state.phoneVerification.verified === false) {
      this.$swal({
        position: 'center',
        icon: 'info',
        title: "Merhaba",
        text: 'Telefonunuz onaysız görünüyor. Size daha iyi bir hizmet verebilmemiz için lütfen telefonunuzu onaylayınız. Telefon onaylama sayfasına yönlendiriliyorsunuz...',
        showConfirmButton: false,
        timer: 4000
      }).then(() => {
        this.$router.push(this.localePath({name: 'verification'}))
      })
    }
    if (this.$auth.loggedIn) {
      if (!this.$auth.strategy.token.get()) {
        this.$auth.logout()
        localStorage.clear()
        window.location.href = "/"
      }
    }
  },
}
