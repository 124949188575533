export default {
  namespaced: true,
  state: () => ({
    id: '',
    slug: '',
    list: [],
  }),
  mutations: {
    setCitySlug(state, payload) {
      state.slug = payload
    },
    setCityList(state, payload) {
      state.list = payload
    }
  }
}
