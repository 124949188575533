export default function ({ $http, app, store, $auth, redirect }) {
  $http.onError(error => {
    if(error.response.status === 404) {
      redirect('/tire/page-not-found')
    }

    if(error.response.status === 400) {
      redirect('/tire/page-not-found')
    }
  })
}
