
export default {
  name: "foot",
  data() {
    return {
      email: '',
      brands: [],
      categories: [],
      menu: [],
    }
  },
  computed: {
    city: {
      get() {
        return this.$store.state.city.slug
      },
      set(value) {
        localStorage.setItem("city", value)
        this.$store.commit('city/setCitySlug', value)
      }
    }
  },
  async fetch() {
    var response = await this.$axios.$get(`/home-page`)
    if (response.status) {
      this.menu = response.data.homePage.brands
    }
  },

  methods: {
    async send() {
      let response = await this.$http.$post(`https://servislet.us14.list-manage.com/subscribe/post?u=1d496bdddaf732e83ce90005d&id=6099678ca1`, {
        email: this.email
      })
    }
  }
}
