export default {
  namespaced: true,
  state: () => ({
    total: 0,
    cart: {},
    items: [],
  }),
  mutations: {
    setTotal(state, payload) {
      state.total = payload
    },
    setCart(state, payload) {
      state.cart = payload
    },
    setItems(state, payload) {
      state.items = payload
    }
  },
  actions: {
    getProducts({commit}, data) {

    }
  }
}
