import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';

Vue.use(VueSweetalert2, {
  "confirmButtonColor": "#06a5d0",
  "cancelButtonColor": "#fa423e",
  "denyButtonColor": "#fa615d"
});

export default ({}, inject) => {
  inject('swal', Vue.swal)
}
