export default async function ({store, redirect, app, $axios, $auth, $http}) {
  if (!$auth.$state.loggedIn) {
    if (!app.$cookies.get("guest_token")) {
      var response = await $http.$post(`/guest-login`)
      if (response.status) {
        var time = new Date();
        time.setHours(time.getHours() + 24);
        app.$cookies.set("token_time", time.getTime())
        app.$cookies.set("guest_token", response.data.access_token)
      }
    } else {
      var response = await $http.$post(`/check`, {
        token: app.$cookies.get("guest_token")
      })

      if (response.data.status === false) {
        var token = await $http.$post(`/guest-login`)
        if (token.status) {
          var time = new Date();
          time.setHours(time.getHours() + 24);
          app.$cookies.set("token_time", time.getTime())
          app.$cookies.set("guest_token", token.data.access_token)
        }
      }
    }
  } else {
    /*try {
      var response = await $http.$post(`/check`, {
        token: $auth.strategy.token.get().replace("Bearer ", '')
      })

      if (response.data.status === false) {
        $auth.logout()
      }
    }catch (error) {
      $auth.logout()
    }*/
  }
}

