export default {
  namespaced: true,
  state: () => ({
    route: '',
  }),
  mutations: {
    setRoute(state, payload) {
      state.route = payload
    },
  },
}
