export default {
  namespaced: true,
  state: () => ({
    list: [],
    totalPage: 999999,
    filter: {}
  }),
  mutations: {
    setTotalPage(state, payload) {
      state.totalPage = payload
    },
    setFilter(state, payload) {
      state.filter = payload
    }
  }
}
