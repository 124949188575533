import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { extend } from 'vee-validate';
import { min,required, email } from 'vee-validate/dist/rules';

extend('email', {
  ...email,
  message: 'Geçerli bir e-posta adresi giriniz.'
});

extend('min', {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ['length'],
  message: 'Αυτό το πεδίο πρέπει να αποτελείται από τουλάχιστον {length} χαρακτήρες.'
});

extend('required', {
  ...required,
  message: 'Τα πεδία είναι υποχρεωτικά.'
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
