export default async function ({store, redirect, app, $axios, $auth, $http, route}) {
  if (!$auth.$state.loggedIn) {
    if (!app.$cookies.get("guest_token")) {
      var response = await $http.$post(`/guest-login`)
      if (response.status) {
        var time = new Date();
        time.setHours(time.getHours() + 24);
        app.$cookies.set("token_time", time.getTime())
        app.$cookies.set("guest_token", response.data.access_token)
      }
    } else {
      var response = await $http.$post(`/check`, {
        token: app.$cookies.get("guest_token")
      })

      if (response.data.status === false) {
        var token = await $http.$post(`/guest-login`)
        if (token.status) {
          var time = new Date();
          time.setHours(time.getHours() + 24);
          app.$cookies.set("token_time", time.getTime())
          app.$cookies.set("guest_token", token.data.access_token)
        }
      }
    }
  } else {
    try {
      if ($auth.strategy.token.get()) {
        var response = await $http.$post(`/check`, {
          token: $auth.strategy.token.get().replace("Bearer ", '')
        })

        if (response.data.status === false) {
          /*var response = await $http.$post(`/guest-login`, {
            token: $auth.strategy.token.get().replace("Bearer ", '')
          })
          if (response.status) {
            this.$auth.strategy.token.set(response.data.access_token)
          }*/
        } else {
          store.commit('phoneVerification/setVerify', response.data.phone_verified)
          store.commit('phoneVerification/setPhone', response.data.phone)
        }
      }
    }catch (error) {

    }
  }
}
