
import Adminsidebar from "@/layouts/adminsidebar";
import Admintopbar from "@/layouts/admintopbar";
import AdminFooter from "~/layouts/admin-footer";
export default {
  name: "admin",
  components: {AdminFooter, Admintopbar, Adminsidebar},
  middleware: 'isAuth',
  data() {
    return {
      isLoad: false
    }
  },
  mounted() {
    setTimeout(function () {

    },3000)
    this.isLoad = true
    if (this.$store.state.phoneVerification.verified === false) {
      this.$swal({
        position: 'center',
        icon: 'info',
        title: "Merhaba",
        text: 'Telefonunuz onaysız görünüyor. Size daha iyi bir hizmet verebilmemiz için lütfen telefonunuzu onaylayınız. Telefon onaylama sayfasına yönlendiriliyorsunuz...',
        showConfirmButton: false,
        timer: 4000
      }).then(() => {
        this.$router.push(this.localePath({name: 'verification'}))
      })
    }
  },
  head: {
    link: [
      {rel: 'stylesheet', type: 'text/css', href: '/css/admin.css'}
    ]
  }
}
