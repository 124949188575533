
import {mapGetters} from 'vuex'
export default {
  name: "user",
  methods: {
    closeUserBar() {
      document.getElementById("myUser").style.width = "0%"
    },
    logout() {
      this.$swal({
        icon: 'question',
        text: 'Είστε βέβαιοι ότι θέλετε να αποσυνδεθείτε;',
        showDenyButton: true,
        confirmButtonText: 'Αποσύνδεση',
        denyButtonText: `Ακύρωση`,
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.clear()
          this.$auth.logout()
          this.$store.commit('cart/setItems', [])
          this.$store.commit('cart/setTotal', 0)
        } else if (result.isDenied) {
          return false
        }
      })
    }
  },
  data() {
    return {
      name: '',
      surname: ''
    }
  },
  mounted() {
    this.$store.commit('user/getUser')
    if (localStorage.getItem("auth.user")) {
      this.name = JSON.parse(localStorage.getItem("auth.user")).name
      this.surname = JSON.parse(localStorage.getItem("auth.user")).surname
      this.$store.commit('setIsAuthenticated', true)
    } else {
      this.$store.commit('setIsAuthenticated', false)
    }
  }
}
