
export default {
  name: "searchbar",
  computed: {
    city: {
      get() {
        return this.$store.state.city.slug
      },
      set(value) {
        this.$store.commit('city/setCitySlug', value)
      }
    }
  },
  methods: {
    removeFilter() {
      this.filters = {
        brand: 'michelin'
      }
    },
    closeSearchBar() {
      document.getElementById("myNav1").style.width = "0%";
    },
    search() {
      var query = {}
      if (this.city) {
        query.city = this.city
      }
      if (this.filters.season) {
        query.season = this.filters.season
      }
      if (this.filters.width) {
        query.width = this.filters.width
      }
      if (this.filters.height) {
        query.height = this.filters.height
      }
      if (this.filters.radius) {
        query.radius = this.filters.radius
      }

      if (this.filters.search_text) {
        query.search = this.filters.search_text
      }

      if (this.filters.family) {
        query.category = this.filters.family
      }

      this.$router.push(this.localePath({name: 'products', params:{brand: this.filters.brand}, query: query}))
      document.getElementById("myNav1").style.width = "0%";
    }
  },
  watch: {
    '$route.query': '$fetch',
  },
  data() {
    return {
      brands: [],
      tabContent: 'tab1',
      filters: {
        brand: 'michelin'
      },
      seasons: [],
      widths: [],
      heights: [],
      radiuses: [],
    }
  },
  async fetch() {
    this.$http.$get(`/tire/listing/brand-with-categories`).then((brands) => {
      if (brands.code === 1000) {
        this.brands = brands.data.brands
      }
    })

    if (Object.keys(this.$store.state.products.filter).length > 0) {
      var filters = this.$store.state.products.filter
      filters.seasons.forEach((item, key) => {
        if (key !== 0) {
          this.seasons.push(item)
        }
      })
      filters.widths.forEach((item, key) => {
        if (key !== 0) {
          this.widths.push(item)
        }
      })
      filters.heights.forEach((item, key) => {
        if (key !== 0) {
          this.heights.push(item)
        }
      })
      filters.radiuses.forEach((item, key) => {
        if (key !== 0) {
          this.radiuses.push(item)
        }
      })
    } else {
      this.seasons = []
      this.widths = []
      this.heights = []
      this.radiuses = []

      this.$http.$get('/tire/listing/seasons').then((seasons) => {
        if (seasons.code === 1000) {
          seasons.data.tireSeasons.forEach((season, key) => {
            this.seasons.push({
              id: key + 1,
              label: season,
              value: season,
              name: season
            })
          })
        }
      })
      this.$http.$get('/tire/listing/widths').then((widths) => {
        if (widths.code === 1000) {
          widths.data.tireProductWidths.forEach((width, key) => {
            this.widths.push({
              id: key + 1,
              label: width,
              value: width,
              name: width
            })
          })
        }
      })
      this.$http.$get('/tire/listing/heights').then((heights) => {
        if (heights.code === 1000) {
          heights.data.tireProductHeights.forEach((height, key) => {
            this.heights.push({
              id: key + 1,
              label: height,
              value: height,
              name: height
            })
          })
        }
      })
      this.$http.$get('/tire/listing/radiuses').then((radiuses) => {
        if (radiuses.code === 1000) {
          radiuses.data.tireProductRadiuses.forEach((radius, key) => {
            this.radiuses.push({
              id: key + 1,
              label: radius,
              value: radius,
              name: radius
            })
          })
        }
      })
    }
  }
}
