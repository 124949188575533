import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _80d5d74c = () => interopDefault(import('../pages/blogs/index.vue' /* webpackChunkName: "pages/blogs/index" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _cc080460 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _2b29b96b = () => interopDefault(import('../pages/admin/addresses/index.vue' /* webpackChunkName: "pages/admin/addresses/index" */))
const _758ffee1 = () => interopDefault(import('../pages/admin/appointments/index.vue' /* webpackChunkName: "pages/admin/appointments/index" */))
const _8a026ce8 = () => interopDefault(import('../pages/admin/cars/index.vue' /* webpackChunkName: "pages/admin/cars/index" */))
const _06b90f5a = () => interopDefault(import('../pages/admin/creditcards/index.vue' /* webpackChunkName: "pages/admin/creditcards/index" */))
const _fdd8bc46 = () => interopDefault(import('../pages/admin/dashboard/index.vue' /* webpackChunkName: "pages/admin/dashboard/index" */))
const _344cf4c0 = () => interopDefault(import('../pages/admin/evaluations/index.vue' /* webpackChunkName: "pages/admin/evaluations/index" */))
const _d5fcec40 = () => interopDefault(import('../pages/admin/favorites/index.vue' /* webpackChunkName: "pages/admin/favorites/index" */))
const _cbf6155c = () => interopDefault(import('../pages/admin/orders/index.vue' /* webpackChunkName: "pages/admin/orders/index" */))
const _957879ea = () => interopDefault(import('../pages/admin/services/index.vue' /* webpackChunkName: "pages/admin/services/index" */))
const _68e35338 = () => interopDefault(import('../pages/admin/user/index.vue' /* webpackChunkName: "pages/admin/user/index" */))
const _1ed6063d = () => interopDefault(import('../pages/login/social.vue' /* webpackChunkName: "pages/login/social" */))
const _0c67dcdf = () => interopDefault(import('../pages/register/social.vue' /* webpackChunkName: "pages/register/social" */))
const _75940c46 = () => interopDefault(import('../pages/register/temp.vue' /* webpackChunkName: "pages/register/temp" */))
const _605d06e8 = () => interopDefault(import('../pages/appointment/index.vue' /* webpackChunkName: "pages/appointment/index" */))
const _2a3fccbd = () => interopDefault(import('../pages/branches/index.vue' /* webpackChunkName: "pages/branches/index" */))
const _3b7d4e49 = () => interopDefault(import('../pages/brands/index2.vue' /* webpackChunkName: "pages/brands/index2" */))
const _59b44b2c = () => interopDefault(import('../pages/campaigns/index.vue' /* webpackChunkName: "pages/campaigns/index" */))
const _07b969e6 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _1d238929 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _12b6192e = () => interopDefault(import('../pages/static/mesafeli.vue' /* webpackChunkName: "pages/static/mesafeli" */))
const _41d65b9f = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _1d279f32 = () => interopDefault(import('../pages/password/forget.vue' /* webpackChunkName: "pages/password/forget" */))
const _179acb0e = () => interopDefault(import('../pages/howdoesitwork/index.vue' /* webpackChunkName: "pages/howdoesitwork/index" */))
const _bc0807aa = () => interopDefault(import('../pages/static/koalay-redirect.vue' /* webpackChunkName: "pages/static/koalay-redirect" */))
const _b4bf1370 = () => interopDefault(import('../pages/verification/index.vue' /* webpackChunkName: "pages/verification/index" */))
const _076ad200 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _64d0132d = () => interopDefault(import('../pages/miscellaneous/error.vue' /* webpackChunkName: "pages/miscellaneous/error" */))
const _1c6abc50 = () => interopDefault(import('../pages/partners/index.vue' /* webpackChunkName: "pages/partners/index" */))
const _3a1a32b1 = () => interopDefault(import('../pages/pay/index.vue' /* webpackChunkName: "pages/pay/index" */))
const _08c60df3 = () => interopDefault(import('../pages/payment/eft/index.vue' /* webpackChunkName: "pages/payment/eft/index" */))
const _09fac0c0 = () => interopDefault(import('../pages/static/bilgilendirme.vue' /* webpackChunkName: "pages/static/bilgilendirme" */))
const _9576b594 = () => interopDefault(import('../pages/static/kafa-rahatligi.vue' /* webpackChunkName: "pages/static/kafa-rahatligi" */))
const _371b54f6 = () => interopDefault(import('../pages/static/guide.vue' /* webpackChunkName: "pages/static/guide" */))
const _5fea9c40 = () => interopDefault(import('../pages/unsubscribed/index.vue' /* webpackChunkName: "pages/unsubscribed/index" */))
const _67cdfa5b = () => interopDefault(import('../pages/admin/cars/add.vue' /* webpackChunkName: "pages/admin/cars/add" */))
const _e12a4dec = () => interopDefault(import('../pages/admin/cars/temp.vue' /* webpackChunkName: "pages/admin/cars/temp" */))
const _0ee7f4b3 = () => interopDefault(import('../pages/admin/payment/eft/index.vue' /* webpackChunkName: "pages/admin/payment/eft/index" */))
const _feceb970 = () => interopDefault(import('../pages/admin/payment/error/index.vue' /* webpackChunkName: "pages/admin/payment/error/index" */))
const _5a3b9e43 = () => interopDefault(import('../pages/admin/payment/success/index.vue' /* webpackChunkName: "pages/admin/payment/success/index" */))
const _60eb4ca8 = () => interopDefault(import('../pages/register/mercan.vue' /* webpackChunkName: "pages/register/mercan" */))
const _4bcb4614 = () => interopDefault(import('../pages/admin/cars/addsteps/brand.vue' /* webpackChunkName: "pages/admin/cars/addsteps/brand" */))
const _539c35be = () => interopDefault(import('../pages/admin/cars/addsteps/car.vue' /* webpackChunkName: "pages/admin/cars/addsteps/car" */))
const _36c4e339 = () => interopDefault(import('../pages/admin/cars/addsteps/fuel.vue' /* webpackChunkName: "pages/admin/cars/addsteps/fuel" */))
const _49c38692 = () => interopDefault(import('../pages/admin/cars/addsteps/gear.vue' /* webpackChunkName: "pages/admin/cars/addsteps/gear" */))
const _02e054b6 = () => interopDefault(import('../pages/admin/cars/addsteps/model.vue' /* webpackChunkName: "pages/admin/cars/addsteps/model" */))
const _335a1984 = () => interopDefault(import('../pages/admin/cars/addsteps/style.vue' /* webpackChunkName: "pages/admin/cars/addsteps/style" */))
const _ffd2282a = () => interopDefault(import('../pages/admin/cars/addsteps/tire.vue' /* webpackChunkName: "pages/admin/cars/addsteps/tire" */))
const _16fffda5 = () => interopDefault(import('../pages/admin/cars/addsteps/version.vue' /* webpackChunkName: "pages/admin/cars/addsteps/version" */))
const _d0cea380 = () => interopDefault(import('../pages/admin/cars/addsteps/year.vue' /* webpackChunkName: "pages/admin/cars/addsteps/year" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _83436e22 = () => interopDefault(import('../pages/admin/address/edit.vue' /* webpackChunkName: "pages/admin/address/edit" */))
const _1d51b3b8 = () => interopDefault(import('../pages/admin/order/randevu.vue' /* webpackChunkName: "pages/admin/order/randevu" */))
const _46bd2564 = () => interopDefault(import('../pages/admin/order/iade.vue' /* webpackChunkName: "pages/admin/order/iade" */))
const _4f228ebd = () => interopDefault(import('../pages/admin/address/index.vue' /* webpackChunkName: "pages/admin/address/index" */))
const _f527802e = () => interopDefault(import('../pages/admin/evaluation/index.vue' /* webpackChunkName: "pages/admin/evaluation/index" */))
const _496e35d2 = () => interopDefault(import('../pages/admin/order/index.vue' /* webpackChunkName: "pages/admin/order/index" */))
const _b62d62e2 = () => interopDefault(import('../pages/branch/index.vue' /* webpackChunkName: "pages/branch/index" */))
const _126ea239 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _2bca6bfd = () => interopDefault(import('../pages/campaign/index.vue' /* webpackChunkName: "pages/campaign/index" */))
const _dd6fb058 = () => interopDefault(import('../pages/faq/detail.vue' /* webpackChunkName: "pages/faq/detail" */))
const _521155a9 = () => interopDefault(import('../pages/new/index.vue' /* webpackChunkName: "pages/new/index" */))
const _46f5ce7c = () => interopDefault(import('../pages/evaluations/order.vue' /* webpackChunkName: "pages/evaluations/order" */))
const _7b556c88 = () => interopDefault(import('../pages/payment/error/index.vue' /* webpackChunkName: "pages/payment/error/index" */))
const _ce29d0fa = () => interopDefault(import('../pages/payment/success/index.vue' /* webpackChunkName: "pages/payment/success/index" */))
const _34d063c6 = () => interopDefault(import('../pages/evaluations/product.vue' /* webpackChunkName: "pages/evaluations/product" */))
const _8e968990 = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "pages/product/index" */))
const _1c470f32 = () => interopDefault(import('../pages/brands/sub.vue' /* webpackChunkName: "pages/brands/sub" */))
const _aa713c76 = () => interopDefault(import('../pages/password/reset.vue' /* webpackChunkName: "pages/password/reset" */))
const _7c127f50 = () => interopDefault(import('../pages/brand/index.vue' /* webpackChunkName: "pages/brand/index" */))
const _999675e2 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _8f3a5340 = () => interopDefault(import('../pages/landing/index.vue' /* webpackChunkName: "pages/landing/index" */))
const _6a13e351 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _5dfa552a = () => interopDefault(import('../pages/product/index2.vue' /* webpackChunkName: "pages/product/index2" */))
const _3014685b = () => interopDefault(import('../pages/static/index.vue' /* webpackChunkName: "pages/static/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blogs",
    component: _80d5d74c,
    name: "blogs___en"
  }, {
    path: "/login",
    component: _cad79e9c,
    name: "login___en"
  }, {
    path: "/register",
    component: _cc080460,
    name: "register___en"
  }, {
    path: "/admin/addresses",
    component: _2b29b96b,
    name: "admin-addresses___en"
  }, {
    path: "/admin/appointments",
    component: _758ffee1,
    name: "admin-appointments___en"
  }, {
    path: "/admin/cars",
    component: _8a026ce8,
    name: "admin-cars___en"
  }, {
    path: "/admin/creditcards",
    component: _06b90f5a,
    name: "admin-creditcards___en"
  }, {
    path: "/admin/dashboard",
    component: _fdd8bc46,
    name: "admin-dashboard___en"
  }, {
    path: "/admin/evaluations",
    component: _344cf4c0,
    name: "admin-evaluations___en"
  }, {
    path: "/admin/favorites",
    component: _d5fcec40,
    name: "admin-favorites___en"
  }, {
    path: "/admin/orders",
    component: _cbf6155c,
    name: "admin-orders___en"
  }, {
    path: "/admin/services",
    component: _957879ea,
    name: "admin-services___en"
  }, {
    path: "/admin/user",
    component: _68e35338,
    name: "admin-user___en"
  }, {
    path: "/login/redirect",
    component: _1ed6063d,
    name: "login-social___en"
  }, {
    path: "/register/redirect",
    component: _0c67dcdf,
    name: "register-social___en"
  }, {
    path: "/register/temp",
    component: _75940c46,
    name: "register-temp___en"
  }, {
    path: "/tire/appointment",
    component: _605d06e8,
    name: "appointment___en"
  }, {
    path: "/tire/branches",
    component: _2a3fccbd,
    name: "branches___en"
  }, {
    path: "/tire/brands",
    component: _3b7d4e49,
    name: "brands-index2___en"
  }, {
    path: "/tire/campaigns",
    component: _59b44b2c,
    name: "campaigns___en"
  }, {
    path: "/tire/cart",
    component: _07b969e6,
    name: "cart___en"
  }, {
    path: "/tire/contact",
    component: _1d238929,
    name: "contact___en"
  }, {
    path: "/tire/distance-selling-contract",
    component: _12b6192e,
    name: "static-mesafeli___en"
  }, {
    path: "/tire/faq",
    component: _41d65b9f,
    name: "faq___en"
  }, {
    path: "/tire/forget-password",
    component: _1d279f32,
    name: "password-forget___en"
  }, {
    path: "/tire/how-does-it-work",
    component: _179acb0e,
    name: "howdoesitwork___en"
  }, {
    path: "/tire/koalay-redirect",
    component: _bc0807aa,
    name: "static-koalay-redirect___en"
  }, {
    path: "/tire/mobile-verification",
    component: _b4bf1370,
    name: "verification___en"
  }, {
    path: "/tire/news",
    component: _076ad200,
    name: "news___en"
  }, {
    path: "/tire/page-not-found",
    component: _64d0132d,
    name: "miscellaneous-error___en"
  }, {
    path: "/tire/partners",
    component: _1c6abc50,
    name: "partners___en"
  }, {
    path: "/tire/pay",
    component: _3a1a32b1,
    name: "pay___en"
  }, {
    path: "/tire/payment-eft-success",
    component: _08c60df3,
    name: "payment-eft___en"
  }, {
    path: "/tire/preliminary-information-form",
    component: _09fac0c0,
    name: "static-bilgilendirme___en"
  }, {
    path: "/tire/servislet-guarantee",
    component: _9576b594,
    name: "static-kafa-rahatligi___en"
  }, {
    path: "/tire/tire-guide",
    component: _371b54f6,
    name: "static-guide___en"
  }, {
    path: "/tire/unsubscribed",
    component: _5fea9c40,
    name: "unsubscribed___en"
  }, {
    path: "/admin/cars/add",
    component: _67cdfa5b,
    name: "admin-cars-add___en"
  }, {
    path: "/admin/cars/temp",
    component: _e12a4dec,
    name: "admin-cars-temp___en"
  }, {
    path: "/admin/payment/eft",
    component: _0ee7f4b3,
    name: "admin-payment-eft___en"
  }, {
    path: "/admin/payment/error",
    component: _feceb970,
    name: "admin-payment-error___en"
  }, {
    path: "/admin/payment/success",
    component: _5a3b9e43,
    name: "admin-payment-success___en"
  }, {
    path: "/tire/register/mercan",
    component: _60eb4ca8,
    name: "register-mercan___en"
  }, {
    path: "/admin/cars/addsteps/brand",
    component: _4bcb4614,
    name: "admin-cars-addsteps-brand___en"
  }, {
    path: "/admin/cars/addsteps/car",
    component: _539c35be,
    name: "admin-cars-addsteps-car___en"
  }, {
    path: "/admin/cars/addsteps/fuel",
    component: _36c4e339,
    name: "admin-cars-addsteps-fuel___en"
  }, {
    path: "/admin/cars/addsteps/gear",
    component: _49c38692,
    name: "admin-cars-addsteps-gear___en"
  }, {
    path: "/admin/cars/addsteps/model",
    component: _02e054b6,
    name: "admin-cars-addsteps-model___en"
  }, {
    path: "/admin/cars/addsteps/style",
    component: _335a1984,
    name: "admin-cars-addsteps-style___en"
  }, {
    path: "/admin/cars/addsteps/tire",
    component: _ffd2282a,
    name: "admin-cars-addsteps-tire___en"
  }, {
    path: "/admin/cars/addsteps/version",
    component: _16fffda5,
    name: "admin-cars-addsteps-version___en"
  }, {
    path: "/admin/cars/addsteps/year",
    component: _d0cea380,
    name: "admin-cars-addsteps-year___en"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/admin/addresses/edit/:address",
    component: _83436e22,
    name: "admin-address-edit___en"
  }, {
    path: "/admin/order/appointment/:order",
    component: _1d51b3b8,
    name: "admin-order-randevu___en"
  }, {
    path: "/admin/order/iade/:order",
    component: _46bd2564,
    name: "admin-order-iade___en"
  }, {
    path: "/admin/addresses/:address",
    component: _4f228ebd,
    name: "admin-address___en"
  }, {
    path: "/admin/evaluation/:feedback",
    component: _f527802e,
    name: "admin-evaluation___en"
  }, {
    path: "/admin/order/:order",
    component: _496e35d2,
    name: "admin-order___en"
  }, {
    path: "/tire/branch/:branch",
    component: _b62d62e2,
    name: "branch___en"
  }, {
    path: "/tire/brands/:brand",
    component: _126ea239,
    name: "brands___en"
  }, {
    path: "/tire/campaign/:campaign",
    component: _2bca6bfd,
    name: "campaign___en"
  }, {
    path: "/tire/faq/:faq",
    component: _dd6fb058,
    name: "faq-detail___en"
  }, {
    path: "/tire/news/:new",
    component: _521155a9,
    name: "new___en"
  }, {
    path: "/tire/order-evaluations/:token",
    component: _46f5ce7c,
    name: "evaluations-order___en"
  }, {
    path: "/tire/payment-error/:order",
    component: _7b556c88,
    name: "payment-error___en"
  }, {
    path: "/tire/payment-success/:order",
    component: _ce29d0fa,
    name: "payment-success___en"
  }, {
    path: "/tire/product-evaluations/:token",
    component: _34d063c6,
    name: "evaluations-product___en"
  }, {
    path: "/tire/product/:product",
    component: _8e968990,
    name: "product___en"
  }, {
    path: "/tire/brands/:brand/:sub",
    component: _1c470f32,
    name: "brands-sub___en"
  }, {
    path: "/tire/reset-password/:token/:id",
    component: _aa713c76,
    name: "password-reset___en"
  }, {
    path: "/tire/brands/:brand/:sub/:sub2",
    component: _7c127f50,
    name: "brand___en"
  }, {
    path: "/blog/:blog",
    component: _999675e2,
    name: "blog___en"
  }, {
    path: "/tire/:brand",
    component: _8f3a5340,
    name: "landing___en"
  }, {
    path: "/tire/:brand/products",
    component: _6a13e351,
    name: "products___en"
  }, {
    path: "/tire/:brand/product/:product",
    component: _5dfa552a,
    name: "product-index2___en"
  }, {
    path: "/:slug",
    component: _3014685b,
    name: "static___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
