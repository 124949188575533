
export default {
  name: "empty2",
  mounted() {
    if (this.$auth.loggedIn) {
      if (!this.$auth.strategy.token.get()) {
        this.$auth.logout()
        localStorage.clear()
        window.location.href = "/"
      }
    }
  },
}
