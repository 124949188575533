
import Cart from "@/components/sidebars/cart";
import Mobile from "@/layouts/mobile";
import User from "@/components/sidebars/user";
import Searchbar from "@/components/search/searchbar";
import Selectplaceholder from "@/components/placeholders/selectplaceholder";

export default {
  name: "navbar",
  components: { Selectplaceholder, Searchbar, User, Mobile, Cart},
  data() {
    return {
      showCartBar: false,
      megaMenu: false,
      cities: [],
      cart: {},
      brands: [],
      name: '',
      surname: ''
    }
  },
  beforeDestroy () {
    this.$nuxt.$off('closeMegaMenuTrigger', () => {
      return false
    })
    this.$nuxt.$off('refreshNavBarCart', () => {
      return false
    })
  },
  created() {
    this.$nuxt.$on('closeMegaMenuTrigger',() => {
      this.closeMegaMenu()
    })
    this.$nuxt.$on('refreshNavBarCart',() => {
      this.getCard()
    })

    this.$nuxt.$on('selectCity',(value) => {
      this[value.field] = value.value
      this.selectCity(value.value)
    })
  },
  computed: {
    cartTotal: {
      get() {
        return this.$store.state.cart.total
      },
    },
    city: {
      get() {
        return this.$store.state.city.slug
      },
      set(value) {
        this.$cookies.set('city', value);
        localStorage.setItem("city", value)
        this.$store.commit('city/setCitySlug', value)
      },
    }
  },
  mounted() {
    if (this.$route.query.city) {
      this.city = this.$route.query.city
    } else if (this.$cookies.get('city')) {
      this.city = this.$cookies.get('city')
    } else if (localStorage.getItem("city")) {
      this.city = localStorage.getItem("city")
    } else if (this.$store.state.city.slug) {
      this.city = this.$store.state.city.slug
    }

    if (this.$auth.loggedIn) {
      if (this.$store.state.user.information) {
        this.name = this.$store.state.user.information.name
        this.surname = this.$store.state.user.information.surname
      } else {
        this.name = JSON.parse(localStorage.getItem("auth.user")).name
        this.surname = JSON.parse(localStorage.getItem("auth.user")).surname
      }
    }
    this.cities = []
    if (this.$store.state.city.list.length > 0) {
      this.$store.state.city.list.forEach((city, key) => {
        if (city.slug !== '') {
          this.cities.push({
            id: city.id,
            label: city.slug,
            value: city.slug,
            name: city.name
          })
        }
      })
    } else {
      this.$http.$get('/regions/1/cities-with-products').then((cities) => {
        cities.data.cities.forEach((city) => {
          this.cities.push({
            id: city.id,
            label: city.name,
            value: city.slug,
            name: city.name
          })
        })
        this.$store.commit('city/setCityList', cities.data.cities)
      })
    }

    this.getCard()
  },
  methods: {
    getCard() {
      this.$axios.get(`/users/carts`).then((response) => {
        this.cart = response.data.data
        if (this.cart.userCart) {
          localStorage.setItem("cart", JSON.stringify(response.data.data))
          if(Object.keys(response.data.data.userCart).length === 0) {
            localStorage.setItem("items", JSON.stringify([]))
            this.$store.commit('cart/setCart', response.data.data)
            this.$store.commit('cart/setItems', [])
            this.$store.commit('cart/setTotal', 0)
          } else {
            localStorage.setItem("items", JSON.stringify(response.data.data.userCart.items ))
            this.$store.commit('cart/setCart', response.data.data)
            this.$store.commit('cart/setItems', response.data.data.userCart.items)
            this.$store.commit('cart/setTotal', response.data.data.tireProductCount)
          }
          return false
          this.$store.commit('cart/setCart', response.data.data)
          this.$store.commit('cart/setItems', response.data.data.userCart.items)
          this.$store.commit('cart/setTotal', response.data.data.response.data.data.tireProductCount)
        }
      })/*.catch(error => {
      if(error.response.data.status_code === 401) {
        localStorage.clear()
        this.$auth.logout()
        this.$store.commit('cart/setItems', [])
      }
    })*/
    },
    login() {
      this.$store.commit('login/setRoute', this.$route.name)
      this.$router.push(this.localePath({name: 'login'}))
    },
    openCartBar() {
      document.getElementById("myCart").style.width = "100%";
    },
    openUserBar() {
      document.getElementById("myUser").style.width = "100%"
    },
    openMobileBar() {
      document.getElementById("myNav").style.width = "100%";
    },
    showMegaMenu() {
      this.megaMenu = true
    },
    closeMegaMenu() {
      this.megaMenu = false
    },
    closeMobileNavBar() {
      document.getElementById("myNav").style.width = "0%"
    },
    closeCart() {
      document.getElementById("myCart").style.left = "0%"
    },
    selectCity(value) {
      if (this.$route.name === 'products___en' || this.$route.name === 'products___tr') {
        var query = {}
        if (this.city) {
          this.$cookies.set('city', this.city)
          query.city = this.city
        }
        if (this.$route.query.season) {
          query.season = this.$route.query.season
        }
        if (this.$route.query.width) {
          query.width = this.$route.query.width
        }
        if (this.$route.query.height) {
          query.height = this.$route.query.height
        }
        if (this.$route.query.radius) {
          query.radius = this.$route.query.radius
        }
        if (this.$route.query.category) {
          query.category = this.$route.query.category
        }

        if (this.$route.query.sort_column) {
          query.sort_column = this.$route.query.sort_column
        }
        if (this.$route.query.sort_direction) {
          query.sort_direction = this.$route.query.sort_direction
        }

        if (this.$route.query.search) {
          query.search = this.$route.$route.query.query.search
        }

        if (this.$route.query.search_text) {
          query.search = this.$route.query.search_text
        }

        if (this.$route.query.min) {
          query.min = this.$route.query.min
        }

        if (this.$route.query.max) {
          query.max = this.$route.query.max
        }

        if (this.run_flat) {
          if (this.run_flat !== '3') {
            query.run_flat = this.$route.query.run_flat
          }
        }
        this.$router.push(this.localePath({
          name: 'products',
          params: this.$route.params,
          query: query
        }))
      } else if (this.$route.name === 'product-index2___tr' || this.$route.name === 'product-index2___en') {
        if (this.$route.query.shop) {
          var url = new URL(window.location.href);
          if (this.$route.query.shop) {
            this.$route.query.shop = ''
          }
          url.searchParams.delete('shop')
          window.history.pushState({}, '', url);
        }
        if (this.city) {
          this.$route.query.city = this.city
          var url = new URL(window.location.href);
          url.searchParams.set('city', this.city);
          window.history.pushState({}, '', url);
        } else {
          var url = new URL(window.location.href);
          if (this.$route.query.city) {
            this.$route.query.city = ''
          }
          url.searchParams.delete('city')
          window.history.pushState({}, '', url);
        }
        this.$nuxt.$emit('getProduct')
      } else if (this.$route.name === 'product___en' || this.$route.name === 'product___tr') {
        if (this.$route.query.shop) {
          var url = new URL(window.location.href);
          if (this.$route.query.shop) {
            this.$route.query.shop = ''
          }
          url.searchParams.delete('shop')
          window.history.pushState({}, '', url);
        }
        if (this.city) {
          this.$route.query.city = this.city
          var url = new URL(window.location.href);
          url.searchParams.set('city', this.city);
          window.history.pushState({}, '', url);
        } else {
          var url = new URL(window.location.href);
          if (this.$route.query.city) {
            this.$route.query.city = ''
          }
          url.searchParams.delete('city')
          window.history.pushState({}, '', url);
        }
        this.$nuxt.$emit('getProduct')
      } else if (this.$route.name === 'branches___en' || this.$route.name === 'branches___tr') {
        this.$router.push(this.localePath({
          name: 'branches',
          query: {
            city: this.city,
          }
        }))
      } else if (this.$route.name === 'branch___en' || this.$route.name === 'branch___tr') {

        if (this.city) {
          this.$router.push(this.localePath({
            name: 'branch',
            query: {
              city: this.city
            }
          }))
        } else {
          this.$router.push(this.localePath({
            name: 'branch',
          }))
        }
      } else {
        this.$store.commit('city/setCitySlug', value)
        this.city = value
      }
    }
  }
}
