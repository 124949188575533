
export default {
  props: ['error'],
  layout: 'empty',
  head() {
    return {
      title: 'Error ',
    }
  },
}
