export default {
  namespaced: true,
  state: () => ({
    filter: {
      brand: '',
      city: '',
      season: '',
      width: '',
      height: '',
      radius: '',
      run_flat: ''
    },
    filters: {
      brands: [],
      city: []
    }
  }),
  mutations: {
    setFilterBrand(state, payload) {
      state.filter.brand = payload
    },
    setFiltersBrand(state, payload) {
      state.filters.brands = payload
    },
    setFilterCity(state, payload) {
      state.filter.city = payload
    },
    setFiltersBrand(state, payload) {
      state.filters.brands = payload
    },
    setFilterSeason(state, payload) {
      state.filter.season = payload
    },
    setFilterWidth(state, payload) {
      state.filter.width = payload
    },
    setFilterHeight(state, payload) {
      state.filter.height = payload
    },
    setFilterRadius(state, payload) {
      state.filter.radius = payload
    },
    setFilterRunFlat(state, payload) {
      state.filter.run_flat = payload
    },
  },
}
