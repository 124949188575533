
export default {
  name: "mobile",
  data() {
    return {
      isSub: false,
      brands: []
    }
  },
  async fetch() {
    if (this.$store.state.brands.list.length > 0) {
      this.brands = this.$store.state.brands.list
    } else {
      var response = await this.$http.$get('/tire/listing/brand-with-categories')
      response.data.brands.forEach((brand) => {
        this.brands.push(brand)
      })
    }
  },
  computed: {
    city: {
      get() {
        return this.$store.state.city.slug
      },
      set(value) {
        localStorage.setItem("city", value)
        this.$store.commit('city/setCitySlug', value)
      }
    }
  },
  methods: {
    closeMobileNavBar() {
      document.getElementById("myNav").style.width = "0%"
    },
  }
}
