export default {
  namespaced: true,
  state: () => ({
    verified: true,
    phone: ''
  }),
  mutations: {
    setVerify(state, payload) {
      state.verified = payload
    },
    setPhone(state, payload) {
      state.phone = payload
    }
  }
}
