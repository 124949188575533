export default {
  namespaced: true,
  state: () => ({
    items: {},
  }),
  mutations: {
    setItem(state, payload) {
      state.items = payload
    },
    async getApi({commit}) {
      var config = await this.$http.$get('/home-page/app-settings')
      this.$store.commit('config/setItem', config.data.appSettings)
    }
  }
}
