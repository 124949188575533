
import _ from 'lodash'
export default {
  name: "cart",
  computed: {
    items: {
      get() {
        return this.$store.state.cart.items
      },
    },
    cart: {
      get() {
        return this.$store.state.cart.cart
      }
    },
    total: {
      get() {
        return this.$store.state.cart.total
      }
    }
  },
  props: {
    isButton: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    closeCart() {
      document.getElementById("myCart").style.width = "0%"
    },
    inc(tire_cart_item_id, tire_product_id, branch_id, service_id) {
      if (service_id) {
        this.$refs[`cart-service-${service_id}`][0].value++
        this.updateItem(tire_cart_item_id, tire_product_id, branch_id, service_id)
      } else {
        this.$refs[`cart-${tire_cart_item_id}`][0].value++
        this.updateItem(tire_cart_item_id, tire_product_id, branch_id)
      }
    },
    des(tire_cart_item_id, tire_product_id, branch_id, service_id) {
      if (service_id) {
        this.$refs[`cart-service-${service_id}`][0].value--
        if (this.$refs[`cart-service-${service_id}`][0].value < 1) {
          this.$refs[`cart-service-${service_id}`][0].value = 1
        }
        this.updateItem(tire_cart_item_id, tire_product_id, branch_id, service_id)
      } else {
        this.$refs[`cart-${tire_cart_item_id}`][0].value--
        if (this.$refs[`cart-${tire_cart_item_id}`][0].value < 1) {
          this.$refs[`cart-${tire_cart_item_id}`][0].value = 1
        }
        this.updateItem(tire_cart_item_id, tire_product_id, branch_id)
      }
    },
    deleteItem(tire_product_id, tire_cart_item_id) {
      this.$swal({
        icon: 'question',
        text: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το στοιχείο στο καλάθι;',
        showDenyButton: true,
        confirmButtonText: 'Ναι, Διαγραφή',
        denyButtonText: `Ακύρωση`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$axios.delete(`/users/carts`, {
            data: {
              tire_product_id: tire_product_id,
              tire_cart_item_id: tire_cart_item_id
            }
          }).then((response) => {
            this.$nuxt.$emit('refreshNavBarCart')
            this.$swal({
              icon: 'success',
              timer: 1000,
              showConfirmButton: false,
              timerProgressBar: true,
              text: "το προϊον διαγράφηκε με επιτυχία."
            }).then(() => {
              if (this.cart.userCart.items.length === 0) {
                document.getElementById("myCart").style.width = "0%"
              }
            })
          })
        } else if (result.isDenied) {
          return false
        }
      })
    },
    updateItem(tire_cart_item_id, tire_product_id, branch_id, service_id) {
      this.$axios.put(`/users/carts`, {
        tire_product_id: tire_product_id,
        branch_id: branch_id,
        quantity: this.$refs[`cart-${tire_cart_item_id}`][0].value,
        tire_cart_item_id: tire_cart_item_id,
      }).then((response) => {
        this.placeholder = false
        if (response.status) {
          this.$swal({
            icon: 'success',
            timer: 1000,
            showConfirmButton: false,
            timerProgressBar: true,
            text: this.$t(response.data.data.status)
          })
          this.$nuxt.$emit('refreshNavBarCart')
        } else {
          this.$swal({
            icon: 'error',

            showConfirmButton: false,
            text: this.$t(response.data.data.status)
          })
          this.$nuxt.$emit('refreshNavBarCart')
        }
      }).catch((error) => {
        let [key, value] = Object.entries(error.response.data.errors)[0]
        if (error.response) {
          this.$swal({
            icon: 'error',
            title: 'Σφάλμα αποθεμάτων!!',
            showConfirmButton: false,
            text: value,
          })
          this.$nuxt.$emit('refreshNavBarCart')
        }
      })
    },
    routeProductDetail(item){
      this.$router.push(this.localePath({name: 'product-index2', params: {product: item.tireProduct.slug,brand: item.tireProduct.brand_slug },query: {shop: this.cart.userCart.branch.slug }}))
    }
  },
}
