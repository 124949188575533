
export default {
  name: "cookie",
  data() {
    return {
      isCookie: false
    }
  },
  mounted() {
    if (localStorage.getItem("cookie")) {
      this.isCookie = false
    } else {
      this.isCookie = true
    }
  },
  methods: {
    closeCookieBanner() {
      this.isCookie = false
      localStorage.setItem('cookie', true)
    }
  }
}
