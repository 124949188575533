export const getters = {
  isAuthenticated(state) {
    return state.auth.loggedIn
  },
  loggedInUser(state) {
    return state.auth.user
  }
};

export const mutations = {
  setLoggedInUser(state, user) {
    state.auth.user = user
  },
  setIsAuthenticated(state, auth) {
    state.auth.loggedIn = auth
  }
}
