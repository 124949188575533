export default function({ store, redirect, route }) {
  var lang = route.name.slice(-2)
  if (!store.state.auth.loggedIn) {
    if (lang === 'tr') {
      return redirect('/giris');
    } else if (lang === 'en') {
      return redirect('/en/login')
    }
  }
}
