export default {
  namespaced: true,
  state: () => ({
    filter: {
      brand: '',
      year: '',
      model: '',
      style: '',
      fuel: '',
      gear: '',
      version: '',
      car: '',
    },
    filters: {
      brands: [],
      years: [],
      model: [],
      model: [],
    }
  }),
  mutations: {
    setFilterBrand(state, payload) {
      state.filter.brand = payload
    },
    setFilterModel(state, payload) {
      state.filter.model = payload
    },
    setFilterStyle(state, payload) {
      state.filter.style = payload
    },
    setFilterFuel(state, payload) {
      state.filter.fuel = payload
    },
    setFilterGear(state, payload) {
      state.filter.gear = payload
    },
    setFilterVersion(state, payload) {
      state.filter.version = payload
    },
    setFilterCar(state, payload) {
      state.filter.car = payload
    },
    //
    setFiltersBrand(state, payload) {
      state.filters.brands = payload
    },
    setFilterYear(state, payload) {
      state.filter.year = payload
    },
    setFiltersYear(state, payload) {
      state.filter.years = payload
    }
  },
}
