
export default {
  name: "vselect-navbar",
  data() {
    return {
      selectValue: '',
      myFilter: (option, label, search) => {
        let temp = search.toLowerCase();
        return option.name.toLowerCase().indexOf(temp) > -1 ||
          option.value.toLowerCase().indexOf(temp) > -1
      }
    }
  },
  computed: {
    selectInfo: {
      get() {
        var data = this.items.filter((item) => {
          if (item.value === this.selectData) {
            return item.label
          }
        })
        if (data.length > 0) {
          return data[0].label
        } else {
          return this.selectData
        }
      },
      set(value) {
        this.selectValue = value
      },
    }
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    id: '',
    labelKey: {
      type: String,
      default: ''
    },
    changeEvent: "",
    placeholder: {
      type: String,
      default: ''
    },
    field: "",
    labelValue: '',
    selectData: {
      type: String,
      default: ''
    },
    clearable: {
      default: true,
      type: Boolean
    },
    placeholder: {
      type: String,
      default: ''
    },
    listType: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    setSelected() {
      if (this.selectValue) {
        this.$nuxt.$emit(this.changeEvent, {
          value: this.selectValue[this.labelValue],
          field: this.field
        })
      } else {
        this.$nuxt.$emit(this.changeEvent, {
          value: '',
          field: this.field
        })
      }
    }
  }
}
