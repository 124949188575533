
export default {
  name: "adminsidebar",
  methods: {
    close_sidebar() {
      let sideBar = document.getElementById("accordionSidebar");
      sideBar.style.width = "0vw"
    },
    navigate(path) {
      this.$router.push(path)
      let sideBar = document.getElementById("accordionSidebar");
      sideBar.style.width = "0vw"
    },
    logout() {
      let sideBar = document.getElementById("accordionSidebar");
      sideBar.style.width = "0vw"
      this.$swal({
        icon: 'question',
        text: 'Είστε βέβαιοι ότι θέλετε να αποσυνδεθείτε;',
        showDenyButton: true,
        confirmButtonText: 'Αποσύνδεση',
        denyButtonText: `Ακύρωση`,
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.clear()
          this.$auth.logout()
          this.$store.commit('cart/setItems', [])
          this.$router.push(this.localePath({name: 'index'}))
          let sideBar = document.getElementById("accordionSidebar");
          sideBar.style.width = "0vw"
        } else if (result.isDenied) {
          return false
        }
      })
    }
  },
}
