import axios from 'axios'

export default function (req, res, next) {
  if (req.url.startsWith('/lastik/urun/')) {
    var query = req.url.split('?')[1]
    var product = req.url.split("/")[3]
    var product = product.split(`?${query}`)[0]
    var brand = ''
    axios.get(`${process.env.URL}/api/v1/web/tire/products/${product}/get-brand`).then((response) => {
      if (response.data.status) {
        brand = response.data.data.brand_slug
        if (query) {
          res.writeHead(301, {"Location": `/lastik/${brand}/urun/${product}?${query}`})
        } else {
          res.writeHead(301, {"Location": `/lastik/${brand}/urun/${product}`})
        }
        res.end()
      } else {
        next()
      }
    }).catch(error => {
      next()
    })
  }
  next()
}
