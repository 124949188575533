export default {
  namespaced: true,
  state: () => ({
    information: {}
  }),
  mutations: {
    setUser(state, payload) {
      state.user = payload
    },
    getUser(state) {
      state.information = JSON.parse(localStorage.getItem("auth.user"))
    }
  }
}
