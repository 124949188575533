
export default {
  name: "admintopbar",
  data() {
    return {
      user: {},
      name: '',
      surname: ''
    }
  },
  mounted() {
    this.$store.commit('user/getUser')
    this.user = this.$store.state.user.information
    this.name = this.user.name.charAt(0)
    this.surname = this.user.surname.charAt(0)
  },
  methods: {
    open_sidebar() {
      let sideBar = document.getElementById("accordionSidebar");
      sideBar.style.width="100vw"
    },
    logout() {
      this.$auth.logout()
      this.$router.push(this.localePath({name: 'index'}))
    }
  }
}
