export default function ({ $axios, app, store, $auth, redirect }) {
  if (app.$cookies.get("guest_token")) {
    $axios.interceptors.request.use(function (config) {
      config.headers.common['Authorization'] = `Bearer ${app.$cookies.get("guest_token")}`
      return config;
    });
  }

  $axios.onError(error => {
    if(error.response.status === 404) {
      redirect('/tire/page-not-found')
    }

    if(error.response.status === 400) {
      redirect('/tire/page-not-found')
    }
  })
}
