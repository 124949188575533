
import Navbar from "./navbar";
import Cookie from "@/components/banners/cookie";

export default {
  components: {Cookie, Navbar},
  data() {
    return {
      config: {}
    }
  },
  head() {
    return {
      meta: [
        { hid: 'robots', name: 'robots', content: 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'}
      ]
    }
  },
  mounted() {
    if (this.$store.state.phoneVerification.verified === false) {
      this.$swal({
        position: 'center',
        icon: 'info',
        title: "Χαίρετε",
        text: 'Το τηλέφωνό σας δεν φαίνεται εγκεκριμένο. Επιβεβαιώστε το τηλέφωνό σας για να σας εξυπηρετήσουμε καλύτερα. Ανακατευθυνθείτε στη σελίδα επιβεβαίωσης τηλεφώνου...',
        showConfirmButton: false,
        timer: 4000
      }).then(() => {
        this.$router.push(this.localePath({name: 'verification'}))
      })
    }
    if (this.$auth.loggedIn) {
      if (!this.$auth.strategy.token.get()) {
        this.$auth.logout()
        localStorage.clear()
        window.location.href = "/"
      }
    }
  },
  async fetch() {
    if (Object.keys(this.$store.state.config.items).length > 0) {
      this.config = this.$store.state.config.items
    } else {
      var config = await this.$http.$get('/home-page/app-settings')
      this.$store.commit('config/setItem', config.data.appSettings)
      this.config = this.$store.state.config.items
    }
  },
}
