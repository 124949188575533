const helpers = {
  name: 'helpers',
  ucFirst(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ''
    }
  },
  slugify(text) {
    if (text) {
      var trMap = {
        çÇ: "c",
        ğĞ: "g",
        şŞ: "s",
        üÜ: "u",
        ıİ: "i",
        öÖ: "o",
      };
      for (var key in trMap) {
        text = text.replace(new RegExp("[" + key + "]", "g"), trMap[key]);
      }
      return text
        .trim()
        .replace(/[^-a-zA-Z0-9\s]+/gi, "")
        .replace(/\s/gi, "-")
        .replace(/[-]+/gi, "-")
        .toLowerCase();
    }
  },
  currenctFormat(locale, price, currency = 'EUR') {
    return new Intl.NumberFormat(locale, {style: 'currency', currency: currency}).format(price)
  },
  upperCaseEach(text) {
    if (text) {
      text = text.toLocaleLowerCase('tr-TR')
      const arr = text.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toLocaleUpperCase('tr-TR') + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return str2
    } else {
      return ''
    }
  },
  upperCaseEachEn(text) {
    if (text) {
      text = text.toLocaleLowerCase('tr-TR')
      const arr = text.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toLocaleUpperCase('en-TR') + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return str2
    } else {
      return ''
    }
  },
  turkishToEnglish(text) {
    if (text) {
      var newText = text.replace('Ğ','G')
        .replace('Ü','U')
        .replace('Ş','S')
        .replace('I','I')
        .replace('İ','I')
        .replace('Ö','O')
        .replace('Ç','C')
        .replace('ğ','g')
        .replace('ü','u')
        .replace('ş','s')
        .replace('ı','i')
        .replace('ö','o')
        .replace('ç','c');

      return newText
    } else {
      return ''
    }
  }
}

export default ({app}, inject) => {
  inject('helpers', helpers)
}
