import { render, staticRenderFns } from "./navbar.vue?vue&type=template&id=4903376a&scoped=true&"
import script from "./navbar.vue?vue&type=script&lang=js&"
export * from "./navbar.vue?vue&type=script&lang=js&"
import style0 from "./navbar.vue?vue&type=style&index=0&id=4903376a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4903376a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VselectNavbar: require('/app/components/vselect/navbar.vue').default})
